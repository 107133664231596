(function ($) {
    $(document)
        .on('click.dropdown', '.dropdown__head', function (event) {
            
            $(this)
            .closest('.dropdown')
            .find('.dropdown__body')
            .removeClass('is-hidden')
            .end()
            .delay(10)
            .queue(function (next) {
                $(this).toggleClass('is-active');
                next();
            });
        })
        .on('transitionend.dropdown', '.dropdown__body', function (event) {
            
            $(this).toggleClass('is-hidden', ! $(this).closest('.dropdown').hasClass('is-active'));
        });
}(jQuery));