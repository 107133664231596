(function ($) {

    function formBeforeSubmit($form) {
        $form.closest('.loader').addClass('is-active');
        $form.find(':submit').prop('disabled', true);
    }

    function formAfterSubmit($form) {
        $form.closest('.loader').removeClass('is-active');
        $form.find(':submit').prop('disabled', false);
    }

    function requestInfoOnSubmit(event) {
        var $form = $(this);

        function done(response) {
            console.log(response);
            $form.trigger('reset')
                 .find('.form__feedback').html($form.data('request-info-message'));
        }

        function fail(response) {
            var message = (response.responseJSON && response.responseJSON[Object.keys(response.responseJSON)[0]]) || $form.data('request-info-error');

            $form.find('.form__feedback').html(message);
        }

        function always() {
            formAfterSubmit($form);
        }

        function scrollToTop() {
            UTIL.scrollto('body', true);
        }


        event.preventDefault();

        formBeforeSubmit($form);

        $.post($form.attr('action'), $form.serialize())
            .done(done)
            .fail(fail)
            .always(always);
    }


    $(document).on('submit.request-info', '[data-request-info]', requestInfoOnSubmit);

}(jQuery));
