(function ($) {

	function updateHeight() {
		top.postMessage({updateHeight: document.body.clientHeight},'*');
	}

	function bindRequestInfoScroll() {
		$('#request-info-btn').on('click', function(){
			top.postMessage({requestInfoOffset: $('#request-info').position().top},'*');
		});
	}

	$(document)
		.on('document-height-changed', updateHeight);

	$(window)
		.on('load', updateHeight)
		.on('load', function(){
			if($('#request-info').length){
				bindRequestInfoScroll();
			}
		});

}(jQuery));