(function ($) {
    
    var $message;
    
    function jsActionPrintOnClick(event) {
        window.print();
    }
    
    function jsActionFavoriteModelOnSubmit(event) {
        var $this = $(this);
        
        function done() {
            $('[data-js-action-favorite-model="' + $this.data('js-action-favorite-model') + '"]').addClass('u-hidden');
            $('[data-js-action-unfavorite-model="' + $this.data('js-action-favorite-model') + '"]').removeClass('u-hidden');
            // $message && $message.trigger('message-dismiss');
            // $message = CHWEB.message("You favorited this model", 'message', 3000);
        }
        
        function fail() {
            $message && $message.trigger('message-dismiss');
            $message = CHWEB.message("You need to login or create an account first", 'warning', 3000);
        }
        
        
        event.preventDefault();
        
        $.post($this.attr('action'), $this.serialize()).done(done).fail(fail);
    }
    
    function jsActionUnfavoriteModelOnSubmit(event) {
        var $this = $(this);
        
        function done() {
            $('[data-js-action-unfavorite-model="' + $this.data('js-action-unfavorite-model') + '"]').addClass('u-hidden');
            $('[data-js-action-favorite-model="' + $this.data('js-action-unfavorite-model') + '"]').removeClass('u-hidden');
            // $message && $message.trigger('message-dismiss');
            // $message = CHWEB.message("You unfavorited this model", 'message', 3000);
        }
        
        function fail() {
            $message && $message.trigger('message-dismiss');
            $message = CHWEB.message("You need to login or create an account first", 'warning', 3000);
        }
        
        
        event.preventDefault();
        
        $.delete($this.attr('action'), $this.serialize()).done(done).fail(fail);
    }
    
    function jsActionSmoothScrollOnClick(event) {
        var $this = $(this),
            $that = $($this.attr('href') || $this.data('js-action-smooth-scroll'));
        
        if ($that.length > 0) {
            if ($this.attr('href')) {
                event.preventDefault();
            }
            top.postMessage({ smoothScroll: $that.offset().top }, '*');
        }
    }
    
    
    $(document)
        .on('click.js-action', '[data-js-action-print]', jsActionPrintOnClick)
        .on('submit.js-action', '[data-js-action-favorite-model]', jsActionFavoriteModelOnSubmit)
        .on('submit.js-action', '[data-js-action-unfavorite-model]', jsActionUnfavoriteModelOnSubmit)
        .on('click.js-action', '[data-js-action-smooth-scroll]', jsActionSmoothScrollOnClick);
}(jQuery));
