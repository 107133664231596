var CHWEB = CHWEB || {};

(function ($) {
    var $message;
    
    function done (response, textStatus, jqXHR) {
        var $response = $(response),
            location = jqXHR.getResponseHeader("X-Location") || this.url || this.href;
        
        window.history.pushState(null, null, location);
        
        $response.children().each(refreshView).each(refreshMaps);
    }
    
    function fail (response) {
        if (response.responseJSON && response.responseJSON.message) {
            $message = CHWEB.message(response.responseJSON.message, 'error');
        }
    }
    
    // function sorterUpdate () {
    //     var $input = $(this),
    //         $form = $input.closest('form'),
    //         $sorter = $input.next('.faceted__sorters__label');
    //     
    //     if ($sorter.length === 0) {
    //         $input
    //         .closest('.faceted__sorters')
    //         .find('.faceted__sorters__label.is-active')
    //         .removeClass('is-active')
    //         .prev(':radio')
    //         .val(function () {
    //             return $(this).val().substring(1);
    //         });
    //     }
    //     else {
    //         $sorter.toggleClass('is-active');
    //         $form.find('#faceted__sorters--current').val($input.val()).prop('checked', true);
    //         $input.val($sorter.hasClass('is-active') ? "-" + $input.val() : $input.val().substring(1));
    //     }
    // }
    
    function refreshView () {
        $('#' + $(this).attr('id')).replaceWith(this);
    }
    
    function refreshMaps () {
        $(this).find('[data-gmaps]').trigger('gmaps-init');
    }
    
    function formBeforeSubmit($form) {
        $form.closest('.loader').addClass('is-active');
        $form.find(':submit').prop('disabled', true);
        $message && $message.trigger('message-dismiss');
    }
    
    function formAfterSubmit($form) {
        $form.closest('.loader').removeClass('is-active');
        $form.find(':submit').prop('disabled', ! $form.is(':valid'));
        $(document).trigger('document-height-changed');
    }
    
    function inputOnChange () {
        var $input = $(this),
            $form = $input.closest('form');
        
        // function done () {
        //     $form
        //     .find('.faceted__sorters')
        //     .find($input.filter(':radio'))
        //     .each(sorterUpdate);
        // }
        
        $form.trigger('submit.faceted');
    }
    
    function formOnSubmit (event) {
        var $form = $(this);
        
        function always () {
            formAfterSubmit($form);
        }
        
        
        event.preventDefault();
        
        formBeforeSubmit($form);
        
        $.get($form.attr('action'), $form.serialize())
        .done(done)
        .fail(fail)
        .always(always);
    }
    
    function linkOnClick(event) {
        var $this,
            $form,
            href,
            hash;
        
        function always () {
            formAfterSubmit($form);
        }
        
        
        if (event.which === 1) {
            $this = $(this);
            $form = $('.faceted');            
            href = $this.attr('href');
            hash = href.substring(href.indexOf('#'));
            event.preventDefault();
            formBeforeSubmit($form);
            
            $.get($(this).attr('href'))
            .done(done)
            .done(function () {
                if (hash && hash !== '#') {
                    UTIL.scrollto(hash);
                }
            })
            .fail(fail)
            .always(always);
        }
    }
    
    
    
    $(document)
        .on('change.faceted', '.faceted :input', inputOnChange)
        .on('submit.faceted', '.faceted', formOnSubmit)
        .on('click.faceted', '.faceted__link', linkOnClick);
}(jQuery));