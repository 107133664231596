(function ($) {
    
    var $message,
        additionalDetails = {
            sqft: 0,
            bedrooms: 0,
            bathrooms: 0
        };
    
    function configuratorOptionOnChange(event) {
        var $option = $(this),
            $options = $('.configurator__option[name="' + $option.attr('name') + '"]'),
            $optionItems = $('.configurator__option-item'),
            $elevation = $('.configurator__option[value="' + $option.data('configurator-elevation') + '"]'),
            $elevationNotes = $('#configurator__force-elevation-notes'),
            $detailsFormat = $('[data-configurator-details-format]'),
            originalDetails = $detailsFormat.data('configurator-details');
        
        additionalDetails.sqft = null;
        additionalDetails.bedrooms = null;
        additionalDetails.bathrooms = null;
        
        $elevation.not(':checked').prop('checked', true).each(configuratorOptionToggleImages);
        $options.each(configuratorOptionUncheckIfForced).each(configuratorOptionToggleImage);
        $optionItems.each(configuratorOptionItemsToggleHiddenPrintClass);
        $('.configurator__option:checked').each(configuratorOptionItemsAdditionalDetails);
        
        $detailsFormat.text(
            $detailsFormat.data('configurator-details-format')
                .replace('%1$s', originalDetails.sqft + additionalDetails.sqft)
                .replace('%2$s', originalDetails.bedrooms + additionalDetails.bedrooms)
                .replace('%3$s', originalDetails.bathrooms + additionalDetails.bathrooms)
        );
        
        $message && $message.trigger('message-dismiss');
        
        if ($elevation.length > 0 && $option.data('configurator-elevation-notes')) {
            $message = CHWEB.message($option.data('configurator-elevation-notes'), 'warning');
        }
    }
    
    function configuratorOptionItemsAdditionalDetails() {
        var $this = $(this),
            details = $this.data('configurator-details-additional');
        
        console.dir(details);
        
        if (details && details.sqft && (details.sqft > additionalDetails.sqft || ! additionalDetails.sqft)) {
            additionalDetails.sqft = details.sqft;
        }
        if (details && details.bedrooms && (details.bedrooms > additionalDetails.bedrooms || ! additionalDetails.bedrooms)) {
            additionalDetails.bedrooms = details.bedrooms;
        }
        if (details && details.bathrooms && (details.bathrooms > additionalDetails.bathrooms || ! additionalDetails.bathrooms)) {
            additionalDetails.bathrooms = details.bathrooms;
        }
    }
    
    function configuratorOptionItemsToggleHiddenPrintClass() {
        var $this = $(this);
        
        $this.toggleClass('u-hidden\@print', $this.find('.configurator__option:checked').length <= 0);
    }
    
    function configuratorOptionUncheckIfForced() {
        var $option = $(this),
            $floorplan = $('.configurator__option[data-configurator-elevation]');
        
        if ( ! $option.is(':checked')) {
            $floorplan
                .filter('[data-configurator-elevation="' + $option.val() + '"]')
                .prop('checked', false)
                .each(configuratorOptionToggleImage);
        }
        else {
            $floorplan
                .prop('checked', function () {
                    var $this = $(this),
                        $elevation = $('.configurator__option[value="' + $this.data('configurator-elevation') + '"]');
                    return $this.is(':checked') ? $elevation.is(':checked') : false;
                })
                .each(configuratorOptionEmptyCheckStandard)
                .each(configuratorOptionToggleImage);
        }
    }
    
    function configuratorOptionEmptyCheckStandard() {
        var $option = $(this),
            $options = $('.configurator__option[name="' + $option.attr('name') + '"]');
            
        if ($options.filter(':checked').length === 0) {
            $options.filter('[value=""]').prop('checked', true);
        }
    }
    
    function configuratorOptionToggleImages() {
        var $option = $(this),
            $options = $('.configurator__option[name="' + $option.attr('name') + '"]');
        
        $options.each(configuratorOptionToggleImage);
    }
    
    function configuratorOptionToggleImage() {
        var $option = $(this);
        
        $('.configurator__image[data-configurator-option="' + $option.val() + '"]')
            .toggleClass('is-active', $option.is(':checked'));
    }
    
    
    
    $(document)
        .on('change.configurator', '.configurator__option', configuratorOptionOnChange);
}(jQuery));